/*-----------------Input----------------------*/
textarea,
input {
  border: none;
  outline: none;
}


textarea:focus,
textarea:focus-visible,
input:focus,
input:focus-visible,
input:focus-within,
input:active,
input:-internal-autofill-selected {
  border: none;
  background-image: none;
  background-color: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  outline: #fff;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number]::-moz-inner-spin-button {
  -webkit-appearance: none;
}

input::placeholder {
  font-size: 14px;
  line-height: 17px;
  color: #0E142F;
}

input[type='checkbox'] {
  width: 19px;
  height: 19px;
  border: 0;
  border: 1px solid;
  margin: 0;
  cursor: pointer;
}

.inputWrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 5px 20px 5px 15px;
  min-height: 40px;
}

.inputWrapperIcon {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 22px;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  min-height: 40px;
}

.inputWrapper i {
  font-size: 18px;
  color: var(--mediumblue);
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
}

.inputWrapper input {
  /* max-width: 200px; */
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0e142f;
  /* max-width: 440px; */
  width: calc(100%);
  border: none;
}

.inputWrapper textarea {
  resize: vertical;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0e142f;
  width: calc(100%);
  border: none;
  height: auto;
  padding: 10px 0;
}

.inputWrapper textarea::-webkit-scrollbar,
.inputWrapper textarea::-moz-scrollbar {
  width: 0;
}

/* .inputWrapper textarea::-webkit-resizer {
  display: none;
} */

.inputWrapper input[type='date'] {
  position: relative;
  padding: 0;
  background-repeat: no-repeat;
  background-position: bottom 0 right 0;
  margin-right: -30px;
  height: 100%;
}

.inputWrapper input[type='date']::after {
  content: "\f133";
  font-family: FontAwesome;
  position: absolute;
  top: 6px;
  right: 0px;
  font-size: 20px;
  color: var(--mediumblue);
}

.inputWrapper input[type='time'] {
  position: relative;
  padding: 0;
  /* background-image: url(../Components/Calendar/clock.svg); */
  background-repeat: no-repeat;
  background-position: bottom 0 right 0;
  margin-right: -30px;

}

.inputWrapper input[type='date']::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.inputWrapper input[type='time']::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.inputWrapper input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  content: '';
  width: 15px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translate(-3px, -50%);
  color: #aaa;
  opacity: 1;
}

.inputWrapper input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}