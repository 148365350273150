$var2: #EF7F1A;

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  position: relative;
  background: -webkit-gradient(linear, left top, right top, from(#ACCBEE), to(#FDFDFE));
  background: -webkit-linear-gradient(left, #ACCBEE 0%, #FDFDFE 100%);
  background: -moz-linear-gradient(left, #ACCBEE 0%, #FDFDFE 100%);
  background: -o-linear-gradient(left, #ACCBEE 0%, #FDFDFE 100%);
  background: linear-gradient(90deg, #ACCBEE 0%, #FDFDFE 100%);
}

.header_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: -1;
  margin-top: -2px;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 80px;
  background: linear-gradient(90deg, #1E3C72 0%, #1E3C72 1%, rgba(42, 82, 152, 1) 100%);
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .bi-list {
    font-size: 30px;
    color: white;
    cursor: pointer;
    display: none;
  }

  .bi-x-lg {
    font-size: 30px;
    color: white;
    cursor: pointer;
  }
  .container_max_width {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1440px;
    padding: 0 20px;
    margin: 0 20px;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  img {
    height: 150px;
    height: 50px;
  }

  .main_logo {
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    .link_language {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: white;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .container_navigations_head {
    width: 60%;
    ul {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      li {
        list-style-type: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: white;
        cursor: pointer;
        font-family: "Montserrat", sans-serif;

        &.entrance_link {
          display: flex;
          justify-content: center;
          background: $var2;
          height: 35px;
          border-radius: 8px;
          width: 100px;
          align-items: center;
        }
        &:hover {
          text-decoration: underline;
          color: white;
        }
      }

      .navbar__link_active li{
        font-weight: 700;
      }
    }
  }
}

.animations_active {
  -webkit-transform: translateY(0px) !important;
  -moz-transform: translateY(0px) !important;
  -ms-transform: translateY(0px) !important;
  -o-transform: translateY(0px) !important;
  transform: translateY(0px) !important;
}

.active_opacity {
  opacity: 1 !important;
}

.navigation_mobile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  background: white;
  height: 100vh;
  top: 79px;
  left: -338px;
  z-index: 4;
  width: 338px;
  -webkit-transition: 500ms;
  -o-transition: 500ms;
  -moz-transition: 500ms;
  transition: 500ms;
}

.navigation_mobile div {
  font-family: "Montserrat", sans-serif;
  list-style-type: none;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  /* color: #000000; */
  cursor: pointer;
  font-weight: bold;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
  /* padding: 10px 40px; */
  /* width: 287,25px - 23px - 23px; */
  height: 65px;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px 20px;
  align-items: center;
  margin-top: 10px;
}

.navigation_mobile div i {
  font-size: 25px;
}

.active_li_navigation {
  background: -webkit-gradient(linear, left top, right top, from(#A3BDED), to(#6991C7)) !important;
  background: -webkit-linear-gradient(left, #A3BDED 0%, #6991C7 100%) !important;
  background: -moz-linear-gradient(left, #A3BDED 0%, #6991C7 100%) !important;
  background: -o-linear-gradient(left, #A3BDED 0%, #6991C7 100%) !important;
  background: linear-gradient(90deg, #A3BDED 0%, #6991C7 100%) !important;
  color: white !important;
}

.link_mobile_navigations {
  color: #14142A;
}


@media (max-width: 1290px) {
  .container_youtube .iframes_youtube {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }

  .active_navigation_mobile {
    -webkit-transform: translateX(338px);
    -moz-transform: translateX(338px);
    -ms-transform: translateX(338px);
    -o-transform: translateX(338px);
    transform: translateX(338px);
  }

  .black_navbar_fon {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }


  .container_navigations_head {
    width: 70%;
  }

  .main_container_about_2 .container_about {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .main_container_about_2 .img_about {
    height: 380px;
  }

  .main_container_about_1 .container_about {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -moz-box-orient: vertical;
    -moz-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .main_container_about_1 .item_about {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .main_container_about_1 .img_about {
    height: 380px;
  }

  .header_container .container_navigations_head {
    display: none;
  }
  .header_container .bi-list {
    display: block;
    margin-right: 30px;
  }
}