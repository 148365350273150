.table_universities {
  background: #FFFFFF;
  box-shadow: 0px 8px 60px rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  width: 450px;
  //height: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  //row-gap: 10px;

  h3 {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    color: #14142B;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    //opacity: 0.7;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-size: 14px;
    margin-top: 15px;
    color: #BBBBCB;
  }

  div {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    //opacity: 0.9;

    a {
      text-decoration: none;
      font-weight: 400;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}