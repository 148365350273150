.container_max_width_register {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 80px - 135px);

  h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #14142b;
    //padding: 10px 20px;
    transition: 500ms;
    //transform: translateY(50px);
    //opacity: 0;
  }
}

.container_register {
  max-width: 1000px;
  width: 100%;
  height: fit-content;
  background-color: white;
  margin: 100px 20px 0 20px;
  border-radius: 10px;
  padding: 40px;
  //height: 630px;

  .box_register {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    transition: 500ms;
    //transform: translateY(50px);
    //opacity: 0;
  }

  .item_register {
    width: 100%;
    height: auto;
    margin-top: 20px;
    span {
      font-size: 16px;
      font-weight: bold;
    }

    .eye {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      cursor: pointer;
    }

    .input {
      background: #ffffff;
      border: 1px solid hsl(0, 0%, 80%);
      box-sizing: border-box;
      border-radius: 4px;
      height: 40px;
      padding: 2px 8px;
      width: 100%;
      outline: none;
    }

    .input-placeholder::placeholder {
      opacity: 0.5 !important;
    }

    .get-code {
      margin-left: 8px;
      font-weight: 500;
      font-family: "Montserrat", sans-serif;
      border: none;
      color: white;
      background: linear-gradient(90deg, #1e3c72 0%, #1e3c72 1%, #2a5298 100%);
      border-radius: 4px;
      width: 200px;
      height: 40px;
      cursor: pointer;
      transition: 200ms;
      display: block;
      position: relative;
    }

    .get-code::after {
      content: "";
      display: block;
      width: 1.2em;
      height: 1.2em;
      position: absolute;
      left: calc(50% - 0.75em);
      top: calc(50% - 0.75em);
      border: 0.15em solid transparent;
      border-right-color: white;
      border-radius: 50%;
      animation: button-anim 0.7s linear infinite;
      opacity: 0;
    }

    @keyframes button-anim {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .get-code.loading {
      color: transparent;
      opacity: 0.8;
    }

    .get-code.loading::after {
      opacity: 1;
    }
  }
}

.title_register {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #0e142f;
}

.container_img {
  border: 1px solid #e4e7eb;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 2px 2px 1px 2px;
  width: 163px;
  border-radius: 8px;

  img {
    max-width: 157px;
    object-fit: cover;
    height: 150px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
  }
}

.img_input {
  margin-top: 10px;

  input {
    display: none;
  }

  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    border: 1px solid #91a3cd;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 15px;
    cursor: pointer;
    display: block;
    margin: auto;
    text-align: center;
    color: white;
    background: #234480;
    transition: 300ms;

    &:hover {
      //background: linear-gradient(90deg, #1E3C72 0%, #1E3C72 1%, rgba(16, 65, 143, 0.63) 120%);
      transform: translateY(3px);
    }
  }

  button {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    border: 1px solid #ef7f1a;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 15px;
    cursor: pointer;
    display: block;
    margin: auto;
    text-align: center;
    color: white;
    background-color: #ef7f1a;
    transition: 300ms;

    &:hover {
      transform: translateY(3px);
    }
  }
}

//.Select {
//  font-size: 14px !important;
//  min-width: 150px;
//  width: 100%;
//  text-align: left;
//}
//
//.my_select__control {
//  border: 1px solid #e4e7eb !important;
//  cursor: pointer !important;
//  height: 40px;
//}
//
///* .my_select__value-container {} */
//
//.my_select__indicator-separator {
//  display: none;
//}
//
//.my_select__indicator {
//  padding-right: 12px !important;
//}
//
//.my_select__indicators svg {
//  width: 25px;
//  height: 25px;
//  color: var(--mediumblue);
//}
//
//.my_select__menu {
//  padding: 0 !important;
//  box-shadow: 0 10px 40px 0 rgba(68, 88, 144, 0.68) !important;
//  border: 1px solid #e4e7eb;
//  margin: 2px !important;
//  width: calc(100% - 4px) !important;
//  background-color: var(--gray) !important;
//  z-index: 2 !important;
//}
//
//.my_select__menu-list {
//  padding: 0 !important;
//}
//
//.my_select__option {
//  cursor: pointer !important;
//}
//
//.my_select__option .isSelected {
//  background: chartreuse !important;
//}

.css-6j8wv5-Input {
  height: 38px !important;
  font-family: "Montserrat", sans-serif !important;
}

.css-319lph-ValueContainer {
  padding: 0 8px !important;
  height: 38px !important;
}

.css-b62m3t-container {
  height: 38px !important;
}

.css-1s2u09g-control {
  height: 38px !important;
}

.css-ackcql {
  height: 28px;
}

.loader_register {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 4;
}

.container_captcha {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.footer_register_container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  transition: 500ms;
  align-items: center;
  //transform: translateY(50px);
  //opacity: 0;

  button {
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    border: none;
    //background-color: #14142A;
    color: white;
    background: linear-gradient(90deg, #1e3c72 0%, #1e3c72 1%, #2a5298 100%);
    border-radius: 8px;
    width: 200px;
    height: 40px;
    cursor: pointer;
    transition: 200ms;

    &:disabled,
    &[disabled="disabled"] {
      background: #ffffff;
      color: #14142a;
      border: 2px solid #234480;
    }

    //&:hover {
    //  background: linear-gradient(90deg, #1E3C72 0%, #1E3C72 1%, rgba(16, 65, 143, 0.63) 120%);
    //}

    &:active {
      transform: translateY(3px);
    }
  }
}

.item_register_container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #ef7f1a;
    text-decoration: underline;
  }
}

.link_auth {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #234480;
  text-decoration: underline;
}

.haveReadAgreementAndConfirmContainer {
  display: flex;
  gap: 12px;
  margin-top: 20px;

  p {
    font-size: 14px;
  }

  span {
    color: #234480;
    font-weight: 600;
    cursor: pointer;
  }
}

@media (max-width: 750px) {
  .item_register_container {
    margin-top: 20px;
    flex-direction: column;
    gap: 10px;
  }

  .register_fio_wrapper {
    order: 2;
  }

  .register_image_upload {
    order: 1;
  }

  .container_max_width_register {
    h1 {
      text-align: center;
    }
  }

  .title_register {
    margin-top: 10px;

    span {
      display: none;
    }
  }

  .container_register {
    width: 90%;
    padding: 30px;

    .box_register {
      flex-direction: column;
      gap: 0;
    }
  }

  .header_container {
    img: 40px;
  }

  .container_captcha {
    justify-content: center;
  }

  .footer_register_container {
    flex-direction: column-reverse;
    text-align: center;

    .link_auth {
      margin-top: 10px;
    }

    .btn_register {
      margin-top: 20px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 430px) {
  .container_register {
    width: 100%;
    padding: 15px;
  }
}
