// Variables
$color-black: #282a2b;
$color-white: #ffffff;
$color-blue: #274d8f;
$color-pink: #c6007e;


.error_container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 81vh;
  text-align: center;

  img {
    width: 200px;
  }

  ._404 {
    font-size: 10rem;
    font-family: "Montserrat", sans-serif;
    color: #2a5298;
  }

  .title {
    font-size: 30px;
    color: #2a5298;
  }
}

@media (max-width: 800px) {
  .error_container {
    display: flex;

    img {
      width: 150px;
    }

    ._404 {
      font-size: 7rem;
    }

    .title {
      font-size: 25px;
    }
  }
}

@media (max-width: 800px) {
  .error_container {
    display: flex;

    img {
      width: 120px;
    }

    ._404 {
      font-size: 5rem;
    }

    .title {
      font-size: 20px;
    }
  }
}