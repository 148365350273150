.InputTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.InputTitle span {
    font-size: 1.2rem;
    font-weight: normal;
}

.Input {
    width: 100%;
    height: 3rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    padding: 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
}

.Title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.Text {
    color: #91A3CD;
}



.FooterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.Button {
    width: 100%;
    height: 3rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    padding: 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
}