$marginTop: 40px;

.container_main {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  height: 535px;
  padding-top: 80px;
  display: flex;
  justify-content: center;

  .container_max_width {
    width: 100%;
    max-width: 1440px;
    margin: 0 20px;
  }

  .about_main {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #FFFFFF;
    margin-top: $marginTop;
  }

  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 13px;
    margin-top: $marginTop;

    span {
      color: #EF7F1A;
      text-decoration: underline;
      margin-right: 10px;
    }
  }
}

.container_form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 50px;
  margin-top: $marginTop;
}

.container_table {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .container_table_max_width {
    display: flex;
    justify-content: flex-start;
    //align-items: start;
    flex-wrap: wrap;
    gap: 40px;
    width: 100%;
    max-width: 1440px;
    min-height: 80vh;
  }
}

@media (max-width: 1440px) {


  .container_table {

    .container_table_max_width {
      justify-content: center !important;
    }
  }

  .table_universities {
    margin: 0 20px;
  }

}

@media (max-width: 690px) {
  .container_form {
    flex-direction: column-reverse;
  }

  .container_input_search {
    width: 100%;

    .item_input {
      width: 100%;
    }

    .main_input {
      width: 100%;
    }
  }

  .container_main {

    .about_main {
      font-size: 20px;
    }
  }

  .custom_summary_select {
    width: 100%;
    max-width: 100%;
  }

  .container_main {

    p {
      margin-top: 20px;
    }
  }

  .container_about_text {
    flex-direction: column;
  }

  .header_container img {
    height: 35px;
  }

  .header_container {
    .bi-list {
      font-size: 23px;
    }
  }
}

@media (max-width: 440px) {
  .container_main {
    height: 552px;
  }
}