.custom_summary_select {
  max-width: 200px;
  margin: 10px;
}

.select {
  position: relative;
  min-width: 200px;
}
.select svg {
  position: absolute;
  right: 12px;
  top: calc(50% - 3px);
  width: 10px;
  height: 6px;
  stroke-width: 2px;
  stroke: #9098a9;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: none;
}
.select select {
  -webkit-appearance: none;
  padding: 7px 7px 7px 12px;
  width: 100%;
  box-shadow: 0 1px 3px -2px #9098a9;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  transition: all 150ms ease;
  height: 38px;
  min-width: 120px;
  background: #FFFFFF;
  border: 1px solid #91A0C1;
  border-radius: 10px;
}
.select select:required:invalid {
  color: #5a667f;
}
.select select option {
  color: #223254;
}
.select select option[value=""][disabled] {
  display: none;
}
.select select:focus {
  outline: none;
  border-color: #07f;
  box-shadow: 0 0 0 2px rgba(0,119,255,0.2);
}
.select select:hover + svg {
  stroke: #07f;
}
.sprites {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

option {
  font-family: "Roboto", sans-serif;
  //font-weight: bold;
  color: #234480;
  cursor: pointer;
}


select {
  font-family: "Roboto", sans-serif;
  //font-weight: bold;
  color: #234480;
  cursor: pointer;
}
