.ProgressWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    align-items: center;
    margin-bottom: 40px;
}

.Block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #49D399;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
}

.ProgressWrapper button p {
    position: absolute;
    top: 70px;
}

.ProgressWrapper button i {
    color: #49D399;
    font-size: 22px;
}

.ProgressWrapper button:first-child p {
    left: 0;
}


.ProgressWrapper label {
    border-bottom: 2px dashed #91A3CD;
    width: 100%;
}

.ProgressWrapper button:disabled {
    border-color: #D6D8E7;
    cursor: default;
}

.ProgressWrapper button:disabled p {
    color: #D6D8E7;
}