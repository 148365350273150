$marginTop: 40px;

.container_questions {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  min-height: 40vh;
  padding-top: 8px;
  display: flex;
  justify-content: center;
  //height: 355px;

  .container_max_width_questions {
    width: 100%;
    max-width: 1440px;
    margin: 0 20px;

    p {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      color: #FFFFFF;
      font-size: 13px;
      margin-top: 100px;

      span {
        color: #EF7F1A;
        text-decoration: underline;
        margin-right: 10px;
      }
    }
  }

  .about_questions {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #FFFFFF;
    margin-top: $marginTop;
  }
}

.container_form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 50px;
  margin-top: $marginTop;
}

//.container_table {
//  display: flex;
//  justify-content: center;
//  margin-top: 70px;
//
//  .container_table_max_width {
//    display: flex;
//    justify-content: flex-start;
//    //align-items: start;
//    flex-wrap: wrap;
//    gap: 40px;
//    width: 100%;
//    max-width: 1440px;
//    min-height: 80vh;
//  }
//}
