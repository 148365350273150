.container_about_universities {
  //background-color: red;
  height: 520px;
  width: 100%;
  max-width: 1440px;
  //margin: 0 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
}

.container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  background-color: #ffffff;
  margin-top: 70px;
  font-family: "Montserrat", sans-serif;
  border-radius: 8px;
}

h2 {
  font-size: 22px;
  margin: 20px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;

  small {
    font-size: 0.5em;
  }
}

.container_about_text {
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;

  p {
    a:hover {
      text-decoration: underline;
    }
  }
}


////////////
.btn_contact {
  height: 43px;
  width: 120px;
  color: white;
  background: linear-gradient(90deg, #1E3C72 0%, #1E3C72 1%, #2A5298 100%);
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
}

////////////////



.table_about {
  width: 100%;
  max-width: 1440px;
  color: #14142A;
  font-family: Montserrat, sans-serif;

  tr {

    td {
      height: 70px;
      padding: 15px;

      &.table_head {
        font-size: 15px;
        //font-weight: 300;
        background-color: #EDF2F7;
        //border: 1px solid #14142A;
      }

      &.table_body {
        border-bottom: 1px dashed #b9b9b9;
        font-weight: 500;
        font-size: 16px;
        padding: 10px;
      }
    }
  }
}


.td_inner_columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}

.td_inner_columns p {
  font-size: 13px !important;
}

.main_container_table_about_universities {
  display: flex;
  justify-content: center;

  h1 {
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #14142B;
  }
}



.none_data_text {
  text-align: center;
  color: #234480;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;
}