.wrapper {
  display: grid;
  gap: 20px;
}

.section {
  display: grid;
  gap: 5px;
}

.ponits {
  margin-left: 1.5rem;
}
