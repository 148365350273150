.container_questions {
  display: flex;
  justify-content: center;
  //background-image: url("./../../img/pazl.png");
  background-repeat: no-repeat;

  .content_questions {
    width: 100%;
    max-width: 1440px;
    margin: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .img_pazl {
    //position: absolute;
    //z-index: -1;
    height: auto;
  }
}
.details > summary {
  list-style: none;
}
.details > summary::-webkit-details-marker {
  display: none;
}

.Collapsible {
  width: 100%;
  margin: 0 auto ;
  //box-shadow: 1px 1px 1px 1px white;
  margin-bottom: .5rem;
  border-radius: 10px;
  overflow: hidden;
  //box-shadow: 0 2px 5px 0 white;
  background-color: #EDF2F7;
  padding: 0 10px;
  padding: 1rem;
}

.Collapsible__trigger {
  cursor: pointer;
  color: #14142B;
  font-family: "Montserrat", sans-serif;
  //font-size: 18px;
  font-weight: bold;
}

.li_details {
  margin: 10px 60px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.ul_details {
  color: #14142b;
  background: #ffffff;
  border-radius: 8px;
  padding: 6px 0;
  margin-top: 15px;
}

.title_details {
  font-weight: 500;
  padding: 10px 20px 10px 20px;
  font-family: "Montserrat", sans-serif;
  color: #14142b;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 10px;
}

summary {
  background: #FCFCFC;
  display: block;
  padding-left: 2.2rem;
  position: relative;
  cursor: pointer;
  //font-size: 16px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

summary:before {
  content: '';
  border-width: .4rem;
  border-style: solid;
  border-color: transparent transparent transparent #EF7F1A;
  position: absolute;
  top: 1.3rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: .2rem 50%;
  transition: .25s transform ease;
}

/* THE MAGIC 🧙‍♀️ */
.details[open] > summary:before {
  transform: rotate(90deg);
}


details summary::-webkit-details-marker {
  display:none;
}

details > ul {
  padding-bottom: 1rem;
  margin-bottom: 0;
}

@media (max-width: 1300px) {
  .img_pazl {
    display: none;
  }
  .pazl_item {
    display: none;
  }
}

@media (max-width: 570px) {
  .Collapsible {
    width: 90%;
  }
}