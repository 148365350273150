$marginTop: 40px;

.container_contact {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  height: 440px;
  padding-top: 80px;
  display: flex;
  justify-content: center;

  .container_max_width {
    width: 100%;
    max-width: 1440px;
    margin: 0 20px;
  }

  .about_main {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #FFFFFF;
    margin-top: $marginTop;
  }

  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 13px;
    margin-top: $marginTop;

    span {
      color: #EF7F1A;
      text-decoration: underline;
      margin-right: 10px;
    }
  }
}

.card_contact_universities {
  display: flex;
  justify-content: center;
  margin: 0 20px;

  .card {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    max-width: 1440px;
    flex-wrap: wrap;
  }

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #14142A;
    display: block;
  }

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #EF7F1A;
    margin-top: 10px;
  }
}

.info_contact {
  //display: grid;
  //grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  border-bottom: 1px dashed #5a667f;
  padding: 10px 15px 10px 15px;
  //border-radius: 12px;

  .head {
    font-size: 16px;
    color: #14142A;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    margin-top: 10px;
  }

  .fio_contact {
    font-size: 16px;
    color: #14142A;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    //margin-top: 10px;
  }

  .link_contact {
    //display: flex;
    //justify-content: center;
    //flex-wrap: wrap;
    margin-top: 5px;
    row-gap: 10px;
    //text-align: center;

    div {
      //width: 100%;
      margin-top: 10px;
    }

    a {
      font-family: Roboto, sans-serif;
      text-decoration: none;
      //color: #081F32;

      &:hover {
        text-decoration: underline;
      }
    }

    i {
      font-size: 26px;
      margin: 0 5px 0 20px;
    }
  }
}

@media (max-width: 720px) {
  .info_contact {
    .link_contact {
      flex-direction: column;
      text-align: left;
    }
  }

  .card_contact_universities {
    h3 {
      font-size: 20px;
    }

    p {
      font-size: 18px;
    }
  }
}