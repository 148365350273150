.main_input {
  min-height: 38px;
  outline: 0!important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  padding-left: 10px;
  max-width: 400px;
  width: 60vh;
  background: #FFFFFF;
  border: 1px solid #91A0C1;
  border-radius: 10px;
}

.btn_reset {
  height: 43px;
  width: 110px;
  color: #14142A;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  //font-family: 'Ro';
  font-weight: 600;
  font-size: 16px;
  transition: 300ms;
  border: 2px solid white;

  &:active {
    transform: translateY(3px);
  }

  &:hover {
    //background-color: #234480;
    //border: 2px solid white;
    //color: #FFFFFF;
    transform: translateY(3px);
  }
}

.container_input_search {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  margin: 10px;
}

@media (max-width: 1440px) {
  .container_input_search {

    .main_input {

    width: 40vh;
  }
  }

}

@media (max-width: 870px) {
  .container_input_search {

    .main_input {

    width: 30vh;
  }
  }

}

@media (max-width: 700px) {
  .container_input_search {

    .main_input {

    width: 20vh;
  }
  }

}