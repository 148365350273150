.main_container_table_about_universities_mobile {
  width: 100%;
  display: block;
  margin: auto;

  .item_mobile_about {
    width: 100%;
    //text-align: center;
    background: #fff;
    padding: 15px;
    margin-top: 20px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    font-family: Montserrat, sans-serif;
    color: #14142B;
  }
}

.card_about_mobile {
  width: 100%;
  padding: 5px 0;
  // border-radius: 8px;
  //border-radius: 8px;
  // background-color: #EDF2F7;

  .head {
    font-size: 15px;
    font-weight: 500;
    font-family: Montserrat,sans-serif;
    color: #14142b;
    margin-bottom: 5px;
  }

  .body {
    font-size: 14px;
    font-weight: 400;
    font-family: Montserrat, sans-serif;
    color: #14142B;
  }
}

.table_direction {
  text-align: left;

  .speciality {
    font-size: 15px;
    color: #1f3e75;
    margin-top: 7px;

    .title {
      font-size: 13px;
    }

    .about {
      font-weight: bold;
    }
  }
}