$marginTop: 40px;

.container_open_data {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  min-height: 40vh;
  padding-top: 80px;
  display: flex;
  justify-content: center;

  .container_max_width_open_data {
    width: 100%;
    max-width: 1440px;
    margin: 0 20px;

    p {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      color: #FFFFFF;
      font-size: 13px;
      margin-top: $marginTop;

      span {
        color: #EF7F1A;
        text-decoration: underline;
        margin-right: 10px;
      }
    }
  }

  .about_questions {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #FFFFFF;
    margin-top: $marginTop;
  }
}

.container_form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 50px;
  margin-top: $marginTop;
}

//.container_table {
//  display: flex;
//  justify-content: center;
//  margin-top: 70px;
//
//  .container_table_max_width {
//    display: flex;
//    justify-content: flex-start;
//    //align-items: start;
//    flex-wrap: wrap;
//    gap: 40px;
//    width: 100%;
//    max-width: 1440px;
//    min-height: 80vh;
//  }
//}

.container_card_open_data {
  //margin-top: 50px;
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  margin: 50px 20px 0 20px;

  .card_open_data {
    background: #FFFFFF;
    box-shadow: 0px 8px 60px rgba(0, 0, 0, 0.06);
    border-radius: 14px;
    width: 420px;
    height: 180px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    position: relative;
    transition: 500ms;
    opacity: 0;

    transform: translateY(50px);
  }

  .card_animation_active {
    transform: translateY(0px);
  }

  .icon_head_card_open_data {
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-top: -100px;
    color: white;
    background-color: #2c5de4;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 30px;
    margin: -60px auto 0 auto;
    cursor: pointer;
    border: 2px solid #2c5de4;
    transition: 300ms;

    &:hover {
      background-color: #F7F7FC;
      color: #2c5de4;
    }
  }

  //.card_open_data {
  //  margin-top: 20px;
  //}

  .title_card_open_data {
    margin-top: 20px;
    color: #14142A;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    line-height: 20px;
  }

  .open_open_data {
    color: #14142A;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    //margin-top: 70px;
    text-align: center;
    height: auto;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 580px) {
  .container_card_open_data {
    .card_open_data {
      width: 80vw;
      height: 220px;
    }

    .icon_head_card_open_data {
      width: 70px;
      height: 70px;
    }
  }
}